import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
    ...theme,

    primaryColor: '#004982',
    secondaryColor: '#c5a750',

    accordionHeaderBackgroundColor: '#004982',
    modalHeaderBackgroundColor: '#004982',
    tabItemColor: '#ffffff',
    tabsBackgroundColor: '#ffffff',
    tabActiveBackgroundColor: '#004982',
    tabsHeaderPadding: '0',
    tabActivePadding: '4px',
    navbarBackgroundColor: '#004982',
    navbarButtonHoverColor: '#ffffff',
    navbarButtonTextColor: '#ffffff',
    navbarButtonHoverTextColor: "#004982",
    navbarTextTransform: 'uppercase',
    logoutButtonBackgroundColor: '#ffffff',
    logoutButtonTextColor: "#004982",
    navbarButtonActiveTextColor: "#004982",

    paginationButtonBackgroundColor: '#004982',
    paginationButtonHoverBackgroundColor: '#6588a8',

    tableHeaderBackgroundColor: '#004982',
    searchButtonBackgroundColor: '#c5a750',

    defaultButtonBackgroundColor: '#004982',
    primaryButtonBackgroundColor: '#c5a750',
    secondaryButtonBackgroundColor: '#c5a750',
    colorsPalette: [
        "#002242", "#144773", "#346ea4", "#4e8ab8", "#6faed2", "#9ecbe3"
    ]
}

const App = () => (
    <ThemeProvider theme={customTheme}>
        <GlobalStyles />
        <Routes />
        <Footer />
    </ThemeProvider>
);

export default App;