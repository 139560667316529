// Here we can turn on/off items which are displayed at navbar and modal tabs by changing 'display' key

export const userSettings = {
    navbar: [
        {
            display: true,
            label: "Published articles",
            path: "/published"
        },
        {
            display: true,
            label: "Institutions",
            path: "/institutions"
        },
        {
            display: true,
            label: "Deals",
            path: "/deals"
        },
        {
            display: true,
            label: "PID Enrichment",
            path: "/enrichment"
        },
    ],
    details: [
        {
            display: true,
            label: "Metadata",
        },
        {
            display: true,
            label: "JSON",
        },
        {
            display: true,
            label: "Events",
        },
        {
            display: true,
            label: "Messages",
        },
        {
            display: false,
            label: "Affiliations",
        },
    ],
};