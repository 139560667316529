import { publisher_id } from "../constants";
import { axiosInstance, axiosOASBInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";
import { noPidArticles } from "../mocks/noPidArticles";


export async function getArticles(searchValue, offset, perPage, sortBy, sortDir, selection, filterValue = "") {
    return await axiosInstance
        .get(`pubtrack/tracker/articles?query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&selection=${selection}&issn=${filterValue}`)
        .catch(handleErrors)
};

export async function getArticleDetails(selectedMessage) {
    return await axiosInstance
        .get(`pubtrack/tracker/article/${selectedMessage}`)
        .catch(handleErrors)
};

export async function getJournals() {
    return await axiosOASBInstance
        .get(`/journal/publisher/${publisher_id}`)
        .catch(handleErrors)
};

export async function getNoPidArticles() {
    return await axiosInstance
        .get(`pubtrack/tracker/reports/articles/withoutidentifiers`)
        .catch(handleErrors)
    // return Promise.resolve({ data: noPidArticles })
};

export async function updateArticleDetails(selectedMessage, articleDetails) {
    return await axiosInstance
        .put(`pubtrack/tracker/article/institutions/${selectedMessage}`, { ...articleDetails })
        .catch(handleErrors)
    // return Promise.resolve();
};

export async function getP1Messages() {
    return await axiosInstance
        .get(`pubtrack/tracker/reports/articles/messages`)
        .catch(handleErrors)
};
