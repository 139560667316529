export const URL = `${process.env.REACT_APP_API_BASE_URL}`;
export const OASB_URL = `${process.env.REACT_APP_API_OASB_URL}`;
export const publisher_id = `${process.env.REACT_APP_PUBLISHER_ID}`;

export const itemsPerPage = 10;

export const allFilterOption = {
    "issn": "",
    "title": "All journals",
    "id": "All journals"
};

export const dealDefaultFilterOption = {
    "institution": "N.A.",
    "count": null,
    "rorid": null
};

