import React, { useState, useEffect } from "react";
import { getArticles, getArticleDetails, getJournals, updateArticleDetails, getP1Messages } from "../../helpers/articles";
import { itemsPerPage, allFilterOption } from "../../constants";
import { getLastYearArticles } from "../../helpers/statistics";
import { MDBCol, MDBRow } from 'mdbreact';
import {
    BarChart,
    ArticleDetailsModal,
    Card,
    CustomTable,
    PieChart
} from "pubtrack-frontend-library";
import { userSettings } from "../../user-settings";
import { saveInstitutionRor } from "../../helpers/institutions";


const Articles = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [articles, setArticles] = useState([]);
    const [p1Messages, setP1Messages] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'manuscript ID',
                accessor: 'manuscript_id',
                sortable: true
            },
            {
                Header: 'DOI',
                accessor: 'doi',
                sortable: true
            },
            {
                Header: 'journal title',
                accessor: 'journal',
                sortable: true
            },
            {
                Header: 'manuscript title',
                accessor: 'manuscript_title',
                sortable: true
            },
            {
                Header: 'last event',
                accessor: 'lastevent',
                sortable: false
            }
        ], []
    );

    const addAllFilterOption = arr => {
        const isPresent = !!arr.find(item => item.title === "All journals");
        if (!isPresent) {
            arr.unshift(allFilterOption);
        }
        return arr;
    };

    const getArticlesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir, filterValue }) => {
        return getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "published", filterValue)
            .then(res => ({ data: res.data.articles, total: res.data.total }))
    };

    const getFilterData = () => {
        return getJournals()
            .then(res => addAllFilterOption(res.data));
    };

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    useEffect(() => {
        getLastYearArticles()
            .then(res => setArticles(res.data))

        getP1Messages()
            .then(res => setP1Messages(res.data));
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
        }
    }, [selectedMessage]);

    const updateArticle = data => {
        return saveInstitutionRor(selectedMessage, data)
            .then(() => {
                getArticleDetails(selectedMessage)
                    .then(res => setArticleDetails(res.data))
            });
    };


    return (
        <div className="container">
            <MDBRow className="my-5">
                <MDBCol md='8' className="my-4">
                    <Card
                        title="New articles per month:"
                        content={<BarChart
                            data={articles}
                            xAxisKey="month"
                            yAxisKey="count"
                            color={"colorsPalette"}
                        />}
                    />
                </MDBCol>

                <MDBCol md='4' className="my-4">
                    <Card
                        title="With/without P1:"
                        content={<PieChart
                            data={p1Messages}
                            labelKey="label"
                            valueKey="count"
                        />}
                    />
                </MDBCol>
            </MDBRow>

            <div className="mb-5">
                <CustomTable
                    columns={columns}
                    itemsPerPage={itemsPerPage}
                    getTableData={getArticlesData}
                    getFilterData={getFilterData}
                    onSelectRow={onSelectRow}
                    sortByDefault={'created'}
                    sortDirDefault={'desc'}
                />
            </div>

            <ArticleDetailsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                articleDetails={articleDetails}
                tabItems={userSettings.details}
                updateArticle={updateArticle}
            />
        </div>
    );
};


export default Articles;